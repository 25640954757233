import { graphql } from "gatsby"
import React from "react"
import "../stylesheets/general.css"
import "../stylesheets/about.css"
import {
  AboutCarousel,
  AchievementCard,
  Button,
  Card,
  Content,
  Hero,
  Layout,
  ScoreCard,
  LogoBox,
  Subscribe,
} from "../components"
import SEO from "../components/SEO"
import {CTATwo} from "../components/CTATwo"
import { H1, LogoItem } from "../elements"

const scores = [
  {
    title: "Internship",
    value: "200",
    quote: "We train a lot of young people.",
    description:
      "Our A- Game Internship Programme has worked with over 200 interns from Jamaican universities and high schools. We’re proud to mentor and provide opportunities for young, bright minds because a productive future requires the best human capital.",
  },
  {
    title: "Staff Happiness Survey",
    value: "95%",
    quote: "People Love It Here!",
    description:
      "95% of our team Indicated they would recommend IPP to a friend. We believe that our culture has helped us to retain our staff members over the years. Happy people do mind blowing work, and it shows in our portfolio.",
  },
  {
    title: "Recognition",
    value: "8",
    quote: "Create value, reap the rewards.",
    description:
      "The last 8 years have been a constant flow of energy and powerful results. Our team is recognized by global organizations such as the Branson Centre Caribbean, Virgin Unite, DMZ YYZ, the Development Bank of Jamaica (DBJ), and the Science and Research Council (SRC) as the innovators of the present, and shapers of the future.",
  },
  {
    title: "Innovation Rewards",
    value: "3",
    quote: "We convert imagination into reality.",
    description:
      "Innovative minds live here and we have the awards to prove it. Last year our team copped three Innovation awards and was the finalist for the national innovation awards. Next step commercialization.",
  },
  {
    title: "Countries",
    value: "3",
    quote: "Success Begins at Home.",
    description:
      "IPP has transformed into a regional company currently operating in Jamaica, St. Lucia and Trinidad & Tobago. Our mission – scale regionally first by unearthing the best talent and intellectual capital from the Caribbean to the world. Small islands, big ideas. The future starts here.",
  },
  // {
  //   title: "Growth",
  //   value: "100%",
  //   quote: "Do the Math. Science = Growth.",
  //   description: "Our strategy is driven by data and grounded in partnerships. IPP has been growing at over 100% for the last 4 years and we just won’t stop.",
  // },
  // {
  //   title: "Teams",
  //   value: "7",
  //   quote: "We are solution engineers.",
  //   description:
  //     "Our diversity is our strength. We have 7 teams made up of some of the best and brightest minds, excited to support businesses and brand solve problems with the latest tech, purpose-driven marketing and cutting-edge experiential campaigns.",
  // },
  // {
  //   title: "Brands",
  //   value: "32",
  //   quote: "Great brands love to work with us.",
  //   description:
  //     "Over the last 3 years, we’ve been lucky to work with 32 of the Caribbean’s Best brands across industries.",
  // },
  
]
const achievements = [
  {
    year: "2019",
    name: "DMZ",
    description: "Top Tech Incubator",
  },
  {
    year: "2019",
    name: "Development Bank of Jamaica",
    description: "DBJ Innovation Grant",
  },
  {
    year: "2019",
    name: "Richard Branson",
    description: "Maverick 1000",
  },
  {
    year: "2018",
    name: "Branson Centre",
    description: "Scale Up Programme",
  },
]
const AboutPage = ({ data }) => {
  const ctaImages = [
    data.ctaImgMobile.fluid,
    {
      ...data.ctaImgDesktop.fluid,
      media: `(min-width: 768px)`,
    },
  ]
  return (
    <Layout>
      <SEO title={"About Us"} description={"We create meaningful relationships and position your brand to drive results."} />
      <Hero
        image={data.heroImage.fluid}
        title="We Are In The Business Of "
        strikeWord="Transformation."
        description="We create meaningful relationships and position your brand to drive results."
        buttonTitle="Play Video"
        videoUrl="https://itspixelperfect.s3.amazonaws.com/videos/About+IPP+NO+TXT+2.mp4"
        videoId="uBuUh6pny9c"
        hasBgVideo={true}
      />
      <Content spacing>
        <Card
          name="Who We Are."
          margin="0 0 82px 0"
          title="We are Pioneers and Problem Solvers."
          description="Since 2011, our award-winning team has been inventing great work for great brands. Our current client roster spans three Caribbean territories and major international brands, covering a range of industries to include media, retail, consumer packaged goods and health and wellness. Our clients increasingly look to us to solve challenges related to Marketing Transformation, Experience Transformation and Digital Transformation. For almost a decade, we have been delivering industry defying results. 
          "
        />

        <Card
          name="What We do."
          margin="0 0 82px 0"
          title="We’re in the Business of Transformation."
          description="People no longer go online; they live there. Your brand deserves a team that understands how to seamlessly bridge both worlds. Our toolkit of innovation and creativity includes beautiful and functional websites, chatbots, mobile apps and data visualizations that position businesses to effectively navigate the constant waves of disruption. We are experienced storytellers using data-driven content to captivate, wow and create meaningful relationships with clients and customers. At IPP, we understand the power of linking marketing, technology and memorable experiences to your bottom line."
        >
          <Button className="viewourbtn" to="/services">View Our Services</Button>
        </Card>
        <H1 large>
          Our <br /> History
        </H1>
      </Content>
      <AboutCarousel />
      <Content>
        <Card margin="0 0 76px 0" name="Recent Achievements.">
          {achievements.map((achievement, index) => (
            <AchievementCard key={index} achievement={achievement} />
          ))}
        </Card>
        <Card
          margin="0 0 125px 0"
          grid="2x4"
          name="Clients love to Work with us."
        >
            <LogoBox/>
        </Card>
        <Card  grid="2x2" name="Our Scoresheet.">
          {scores.map((score, index) => (
            <ScoreCard key={index} score={score} />
          ))}
        </Card>
      </Content>
      <CTATwo image={ctaImages} title="Give Where You Live." description="Our team is committed to making Jamaica a better place for everyone. IPP supports a number of community projects and initiatives from exposing students to future skills and careers through our internship programme to the sponsorship of events like Shaggy and Friends making a big impact on the health and quality of life of vulnerable families across Jamaica. A happy team shares the gift of hope with their community." />
      <Subscribe />
    </Layout>
  )
}

export const query = graphql`
  query {
    rjr: file(relativePath: { eq: "rjrpng.png" }) {
      publicURL
    }
    gk: file(relativePath: { eq: "gk.svg" }) {
      publicURL
    }
    norbrook: file(relativePath: { eq: "norpg.png" }) {
      publicURL
    }
    hl: file(relativePath: { eq: "hlpng.png" }) {
      publicURL
    }
    atl: file(relativePath: { eq: "atlpg.png" }) {
      publicURL
    }
    cb: file(relativePath: { eq: "cbpng.png" }) {
      publicURL
    }
    salada: file(relativePath: { eq: "salpng.png" }) {
      publicURL
    }
    wisynco: file(relativePath: { eq: "wisynco.svg" }) {
      publicURL
    }
    heroImage: imageSharp(fluid: { originalName: { eq: "abouthero.png" } }) {
      fluid(quality: 100) {
        ...GatsbyImageSharpFluid
      }
    }
    ctaImgDesktop: imageSharp(
      fluid: { originalName: { eq: "goodcitizen.png" } }
    ) {
      fluid(quality: 100) {
        ...GatsbyImageSharpFluid
      }
    }
    ctaImgMobile: imageSharp(fluid: { originalName: { eq: "goodcitizen.png" } }) {
      fluid(quality: 100) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`
export default AboutPage
