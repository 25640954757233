import React from "react"
import { H1, P } from "../elements"
import { CTACard2, CTAImage2, CTAWrapper2} from "../elements/CTAElements"
import "../stylesheets/CTA.css"

export const CTATwo = ({ image, title, description, children }) => {
  return (
    <CTAWrapper2 className="ctawrapper2">

      <CTAImage2 fluid={image} className="ctaimg2" />
      <CTACard2 className="ctacard2">
      <H1 black>{title}</H1>
      <P black margin="20px 0 28px 0">
        {description
          ? description
          : "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa."}
      </P>
      {children}
    </CTACard2>
    </CTAWrapper2>
  )
}
